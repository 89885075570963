import React from 'react'
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
const Visitschart = () => {
    const data = [5, 10, 15, 10, 20, 5, 10, 15, 10, 20]; // Sample data

    return (
        <div className='w-100'>
        <Sparklines data={data} width={100} height={30}>
            <SparklinesLine color="#bf7ac0" />
            {/* <SparklinesSpots /> */}
        </Sparklines>
        </div>
    )
}

export default Visitschart
