import { useSelector } from "react-redux"
import Visitschart from "../components/charts/Visitschart"
import Leadsales from "../components/charts/Leadsales"


export default function Dashboard() {

    return (
        <section className="wrapper">
            <p className="heading">Dashboard</p>
            <div className="card rounded-4 ">
                <div className="card-body">
                    <div className="row top-chart-bar py-4">
                        <div className="col-lg col-md-6 d-flex">
                            <div>
                                <p>32,451</p>
                                <span>Visits</span>
                            </div>
                            <div className="top-chartbar-chart">
                                <Visitschart />
                            </div>
                        </div>
                        <div className="col-lg col-md-6 d-flex">
                            <div>
                                <p>32,451</p>
                                <span>Visits</span>
                            </div>
                            <div className="top-chartbar-chart">
                                <Visitschart />
                            </div>
                        </div>
                        <div className="col-lg col-md-6 d-flex">
                            <div>
                                <p>32,451</p>
                                <span>Visits</span>
                            </div>
                            <div className="top-chartbar-chart">
                                <Visitschart />
                            </div>
                        </div>
                        <div className="col-lg col-md-6 d-flex">
                            <div>
                                <p>32,451</p>
                                <span>Visits</span>
                            </div>
                            <div className="top-chartbar-chart">
                                <Visitschart />
                            </div>
                        </div>
                        <div className="col-lg col-md-6 d-flex">
                            <div>
                                <p>32,451</p>
                                <span>Visits</span>
                            </div>
                            <div className="top-chartbar-chart">
                                <Visitschart />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row mt-4 mx-0">
                <div className="col-lg-8 px-0 pe-lg-2 mt-2">
                    <div className="card">
                        <div className="card-body">
                            <p className="heading">Lead sales</p>
                            <Leadsales />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 px-0 ps-lg-2 mt-2">
                    <div className="card">
                        <div className="card-body">
                            <p className="heading">Lead sales</p>
                            <Leadsales />
                        </div>
                    </div>
                </div>
          
            </div>
        </section>
    )
};

