import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { app_url } from "../../config";
import axios from "axios";
import OrderCompletedModal from "./OrderCompletedModal";
import { useState } from "react";
import Loader from "../Loader";
export default function PaymentModal({paypalinfostate}) {
    const userdata = localStorage.getItem('customerEtijwaal')
    const token = JSON.parse(userdata).token
   const [isLoading, setloading] = useState(false)
   const [success, setSuccess] = useState(false)

 const handleApprove = async (data, actions) => {
        const order = await actions.order.capture();
        if (order) {
            console.log('Payment was approved!', order, order.purchase_units[0].payments.captures[0].id);
            const customId = data.orderID;
            // console.log('Custom ID:', customId);
            setloading(true)
            axios.post(`${app_url}/api/payment/confirmTopUpOrder`, { systemOrderId: paypalinfostate.topOrder[0].systemOrderId, transactionId: order.purchase_units[0].payments.captures[0].id }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            } )
                .then(response => {
                    if (response.data.success) {
                        document.querySelector('.OrderCompletedModal').click()
                        document.querySelector('.close-modal').click()
                        setloading(false)
                        setSuccess(true)
                    }

                    else {
                        console.log(response.data.message)
                        setloading(false)
                    }
 
                })
                .catch(error => {
                    // toast.error(error)
                    console.log(error);
                    setloading(false)
                });
        }
    };

    const handleError = (err) => {
        console.log('Payment error:', err);
    };





    return (
        <>
            <button type="button" class="btn btn-primary d-none btn_payment" data-bs-toggle="modal" data-bs-target="#paymentModal">
                Launch demo modal
            </button>

                 
            <div class="modal fade" id="paymentModal" tabindex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        {/* <div class="modal-header">
        <h5 class="modal-title" id="paymentModalLabel">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> */}
                        <div class="modal-body">
                            {paypalinfostate === null ? "" :
                             isLoading ? 
                             <div className="parent-loader h-100">
                                     <Loader />
                                 </div>
                                 :
                            <PayPalScriptProvider options={{ "client-id": paypalinfostate?.publishableKey }}>
                                <PayPalButtons
                                    createOrder={(data, actions) => {
                                        // Define the order creation logic here
                                        return actions.order.create({
                                            purchase_units: [
                                                {
                                                    amount: {
                                                        currency_code: paypalinfostate?.topOrder[0].currency.toUpperCase(),
                                                        value: paypalinfostate?.topOrder[0].price,
                                                    },
                                                    custom_id: paypalinfostate?.topOrder[0].systemOrderId,
                                                },
                                            ],
                                        });
                                    }}
                                onApprove={handleApprove}
                                onError={handleError}
                                />
                            </PayPalScriptProvider>
                            }
                            <button type="button"
                             class="btn btn-secondary d-none close-modal"
                             data-bs-dismiss="modal">Close</button>
                        </div>
                        {/* <div class="modal-footer">
        
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
                    </div>
                </div>
            </div>
            <OrderCompletedModal success={success} />
        </>
    )
}