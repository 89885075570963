import React from 'react'
import logo from '../'

const NotFound = () => {
    return (
        <>
            <section class="page_404">
                <div class="container">
                    <div class="row justify-content-center vh-100 align-items-center " >
                     
                            <div class="col-sm-10 col-sm-offset-1  text-center">
                                <div class="four_zero_four_bg">
                                    <h1 class="text-center ">404</h1>


                                </div>

                                <img src={'/assets/logo/e-tijwaal.png'} alt="" className='img404' />
                                <div class="contant_box_404">
                                    <h3 class="h2">
                                        Look's like you're lost
                                    </h3>
                                    <p>the page you are looking for not avaible!</p>

                                    <button to="" onClick={() => window.history.back()} class="btn primary-btn">Go to Home</button>
                                </div>
                            </div>
                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default NotFound
