import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Loader from "../../components/Loader";
import { app_url, environment } from "../../config";
import 'react-loading-skeleton/dist/skeleton.css'
import toast from "react-hot-toast";
import PaymentModal from "../../components/modals/PaymentModal";




const PaymentOrder = () => {
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false)
    const [gateway, setGateway] = useState("")
    const [data, setData] = useState(null)
    const [error, setError] = useState(false)
    const [paypalinfostate, setpaypalinfostate] = useState(null)
    const [ip, setIp] = useState("")
    const localData = localStorage.getItem('customerEtijwaal')
    const token = JSON.parse(localData).token
    const { id } = useParams();
    const recievedFromParams = location.search;
    const systemOrderId = recievedFromParams.slice(2, recievedFromParams.length);
    const url = `${app_url}/api/payment/getTopUpPackageDetails?topupPackageId=${id}`;
    const paymentUrl = `${app_url}/api/payment/createTopUpOrder`;
    const navigate = useNavigate()

    const fetchPackage = async () => {
        setIsLoading(true)
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json()
            handleResponse(data, response);
        } catch (error) {
            setError(true)
            toast.error(error.message)
        }
    }


    const proceedPayment = async () => {
        setIsLoading(true);
        try {
            if (!gateway) {
                toast.error('Please Select a payment method!');
                setIsLoading(false);
                return;
            };

            const response = await fetch(paymentUrl, {
                method: 'POST',
                body: JSON.stringify({ systemOrderId: systemOrderId, packageId: id, gateway: gateway, trackDetails: { IPv4: ip }, environment: environment }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json()
            paymentResponse(data, response);
            toast.success(data.message);
        } catch (error) {
            setError(true)
            toast.error(error.message)
        }

    };


    const handleResponse = (response, apiResponse) => {
        if (response.success) {
            setData(response.topUpPackage[0])
            setIsLoading(false)
        }
        else if (apiResponse.status === 401) {
            navigate('/login')
            throw new Error(response.error)
        }

        else {
            setIsLoading(false)
            throw new Error(response.error);
        }
    }


    const paymentResponse = (response, apiResponse) => {
        if (response.success) {
            console.log(response);
            setpaypalinfostate(response)
            document.querySelector('.btn_payment').click();
            setIsLoading(false)
            setGateway("");
        }
        else if (apiResponse.status === 401) {
            navigate('/login')
            throw new Error(response.error)
        }
        else {
            setIsLoading(false)
            throw new Error(response.error);
        }
    }



    useEffect(() => {
        fetchPackage()
        fetch('https://geolocation-db.com/json/')
            .then(response => response.json())
            .then(data => {
                setIp(data.IPv4);
                console.log(data.IPv4);
            })
    }, [])

    return (
        <section style={{ overflowX: 'hidden' }} className="wrapper">
            <div className="row h-100 align-items-center justify-content-center">
                {
                    isLoading ?
                        (
                            <div className="col-lg-6 h-75">
                                <div className="parent-loader h-100">
                                    <Loader />
                                </div>
                            </div>
                        )
                        :
                        error ?
                            (
                                <div className="col-lg-6 h-75">
                                    <div className="d-flex align-items-center h-75 mt-5 gap-2 shadow-sm bg-white rounded justify-content-center">
                                        <img src="/assets/icons/caution.png" width={100} className="img-fluid" />
                                        <h1 className="text-danger m-0">Something went wrong!</h1>
                                    </div>
                                </div>
                            )
                            :
                            (
                                <div className="col-lg-6">
                                    <div class="card border-0">
                                        <div class="card-header pb-4 bg-none border-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center gap-4">
                                                    <img src={app_url + data?.cardImage} width={75} alt="top-up" />

                                                    <div className="align-self-end mb-2">
                                                        <h2 className="m-0 mb-1 fs-5">{data?.packageName}</h2>
                                                        <p style={{ color: '#62666b', fontSize: '13px' }} className="m-0 fw-light">TOP UP</p>
                                                    </div>
                                                </div>
                                                <div className="align-self-end">
                                                    <span className="fw-lighter d-inline-block">US</span><p className="fw-bold d-inline-block ms-2 fs-4">$ {data?.price}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body pt-4">
                                            <div className="pb-2 mb-3 ">
                                                <span className="fs-5"> <i class="bi bi-globe2 me-2 "></i>Coverage</span> <span className="float-right fs-5">{data?.shortInfo}</span>
                                            </div>
                                            <div className="pb-2 mb-3">
                                                <span className="fs-5"><i class="bi bi-clipboard-data me-2 "></i> Data</span> <span className="float-right fs-5">{data?.includeData}</span>
                                            </div>
                                            <div className="pb-2 mb-3">
                                                <span className="fs-5"><i class="bi bi-calendar-fill me-2 "></i> Validity</span> <span className="float-right fs-5">{data?.day} {data?.day === 1 ? 'Day' : 'Days'}</span>
                                            </div>
                                        </div>
                                        <div style={{ background: '#fff' }} class="card position-relative mt-3 text-center">
                                            <div class="expresscheckout-text d-flex justify-content-center">
                                                <p className="fs-5">Payment Method</p>
                                            </div>
                                            <div class="card-body">
                                                <div class="row justify-content-center expresscheckout">
                                                    <div class="col-md-6 mt-2 px-md-2 px-1">
                                                        <label for="paypalpay">
                                                            <input type="radio" onChange={(e) => setGateway(e.target.value)} name="expresscheckout" id="paypalpay" class="my-1 me-2 form-check-input" value="paypal" />
                                                            Credit/Debit Card
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-end mt-4">
                                        <button onClick={proceedPayment} className="px-4 py-2 border-0 shadow rounded primary-btn">Proceed to payment</button>
                                    </div>
                                </div>
                            )
                }
            </div>
            <PaymentModal paypalinfostate={paypalinfostate} />
        </section>
    )

}








// function PaymentOrderSkeleton() {
//     return (
//       <div className="col-lg-6">
//         <div className="card border-0">
//           <div className="card-header pb-4 bg-none border-0">
//             <div className="d-flex justify-content-between align-items-center">
//               <div className="d-flex align-items-center gap-4">
//                 <Skeleton width={75} height={75} circle />
//                 <div className="align-self-end mb-2">
//                   <Skeleton width={80} height={20} />
//                   <Skeleton width={60} height={15} />
//                 </div>
//               </div>
//               <div className="align-self-end">
//                 <Skeleton width={40} height={20} />
//               </div>
//             </div>
//           </div>
//           <div className="card-body pt-4">
//             <div className="pb-2 mb-3 bg-primary">
//               <span className="fs-5"> <i className="bi bi-globe2 me-2 "></i> <Skeleton /></span>
//               <Skeleton width={100} />
//             </div>
//             <div className="pb-2 mb-3">
//               <span className="fs-5"><i className="bi bi-clipboard-data me-2 "></i> Data</span>
//               <Skeleton width={100} />
//             </div>
//             <div className="pb-2 mb-3">
//               <span className="fs-5"><i className="bi bi-calendar-fill me-2 "></i> Validity</span>
//               <Skeleton width={100} />
//             </div>
//           </div>
//           <div style={{ background: '#fff' }} className="card position-relative mt-3 text-center">
//             <div className="expresscheckout-text d-flex justify-content-center">
//               <Skeleton width={60} />
//             </div>
//             <div className="card-body">
//               <div className="row justify-content-center expresscheckout">
//                 <div className="col-md-6 mt-2 px-md-2 px-1">
//                   <label for="paypalpay">
//                     <input type="radio" name="expresscheckout" id="paypalpay" className="my-1 me-2 form-check-input" value="paypal" />
//                     <Skeleton width={100} />
//                   </label>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="text-end mt-4">
//           <Skeleton width={120} height={40} />
//         </div>
//       </div>
//     );
//   }


export default PaymentOrder