import React from 'react'

const Loader = () => {
    return (
        <>
            <div className="loading">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </>
    )
}

export default Loader
