import React, { useState } from 'react'
import EmailChange from '../components/modals/EmailChange'
import PasswordChange from '../components/modals/PasswordChange'

const AccountInfo = () => {
    const userinfo = JSON.parse(localStorage.getItem('customerEtijwaal'))
    const [firstName, setfirstName] = useState(userinfo?.customer?.first_name)
    const [lastName, setlastName] = useState(userinfo?.customer?.last_name)

    return (
        <>
            <section className='wrapper'>
                <p className="heading">
                    Account Information
                </p>
                <div className="card mobile-card">
                    <div className="card-body p-md-5 p-1">

                        {/* {loading ? <div className='parent-loader'><Loader /></div> : <>
                            {data?.order?.map((item, index) => ( */}
                        <div className='row justify-content-between '>
                            <form className="" onSubmit={(e) => { e.preventDefault() }}>
                                <div className="ac_info">
                                    <label htmlFor="Email">First Name</label> <br />
                                    <input type="text" name="" id="" value={firstName} className=' ' onChange={(e) => setfirstName(e.target.value)} />
                                </div>
                                <div className="ac_info">
                                    <label htmlFor="Email">Last Name</label>
                                    <input type="text" name="" id="" value={lastName} className=' ' onChange={(e) => setlastName(e.target.value)} />
                                </div>
                                <div>
                                    <div className="ac_info mb-2">
                                        <label htmlFor="Phone No">Phone No</label>
                                        <div className="d-flex justify-content-between">
                                            <p>{userinfo?.customer?.mobile}</p>
                                            <i class="bi bi-file-lock2-fill fs-3" ></i>
                                        </div>
                                    </div>
                                    {/* <div className="text-end">
                                        <button className='btn edit-btn-modal' data-bs-toggle="modal" data-bs-target="#EmailChangeModal">EDIT</button>
                                    </div> */}
                                </div>
                                <div>
                                    <div className="ac_info mb-2">
                                        <label htmlFor="Email">Email</label>
                                        <div className="d-flex justify-content-between">
                                            <p>{userinfo?.customer?.email}</p>
                                            <i class="bi bi-file-lock2-fill fs-3" ></i>
                                        </div>
                                    </div>
                                    {/* <div className="text-end">
                                        <button className='btn edit-btn-modal' data-bs-toggle="modal" data-bs-target="#EmailChangeModal">EDIT</button>
                                    </div> */}
                                </div>

                                <div>
                                    <div className="ac_info mb-2">
                                        <label htmlFor="Email">Current Password</label>
                                        <div className="d-flex justify-content-between">
                                            <p>********</p>
                                            <i class="bi bi-file-lock2-fill fs-3" ></i>
                                        </div>
                                    </div>
                                    {/* <div className="text-end">
                                        <button className='btn edit-btn-modal' data-bs-toggle="modal" data-bs-target="#PasswordChangeModal">EDIT</button>
                                    </div> */}
                                </div>
                                {/* <div className="mt-4 d-flex justify-content-end">
                                    <button type='submit' className='btn primary-btn'>Save Changes</button>
                                </div> */}
                            </form>
                        </div>
                        {/* ))}
                        </>
                    } */}
                    </div>
                </div>
            </section>
            <EmailChange />
            <PasswordChange />
        </>
    )
}

export default AccountInfo
