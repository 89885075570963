import React from 'react'

const Footer = () => {
    const year = new Date().getFullYear()
    return (
        <>
            <footer className="footer">
                <span>&copy; {year} All Rights Reserved. By  eTijwaal</span>
            </footer>
        </>
    )
}

export default Footer
