import React, { useEffect, useState } from 'react';
import
   {
    Page, 
    Text, 
    View, 
    Document, 
    StyleSheet, 
    Image, 
    PDFViewer, 
    Link 
    } from '@react-pdf/renderer';
import { useLocation, useNavigate } from 'react-router-dom';
import { app_url } from '../../config';
import toast from 'react-hot-toast';
import Loader from '../../components/Loader';


const styles = StyleSheet.create({
    section: {
        marginVertical: 15,
        marginHorizontal: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    heading: {
        fontSize: 20,
        fontWeight: 'bold',
    },

    page: {
        backgroundColor: '#fff',
        marginVertical: 20
    },
    company: {
        fontWeight: 'extrabold',
        fontSize: 14,
        marginBottom: 10
    },
    image: {
        width: 100, // Adjust the width as needed
    },
    address: {
        fontSize: 12,
        fontWeight: 'light',
        marginBottom: 10
    },
    verticalSection: {
        marginVertical: 15,
        marginHorizontal: 30
    },
    order: {
        fontSize: 12,
        fontWeight: 'ultrabold',
        marginBottom: 20,
    },
    ordersWrapper: {
        flexDirection: 'row',
        gap: 40,
        marginHorizontal: 30,
    },
    lineBreak: { borderBottom: 0.3, borderColor: 'black', marginHorizontal: 30 }
});








export default function Pdf() {

    const userdata = localStorage.getItem('customerEtijwaal')
      const token = JSON.parse(userdata).token
    const [data, setData] = useState([])
     const [isLoading, setIsLoading] = useState(false)
     const [error, setError] = useState(false)
     const location = useLocation() 
     const parameters = location.search;
    const id = parameters.slice(4, parameters.length)
    const url = `${app_url}/api/order/getOrderReceiptByOrderIdForCportal?orderId=${id}`;
   const navigate = useNavigate()

      
    const fetchUserDetails = async () => {
        setIsLoading(true)
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json()
            handleResponse(data, response);
         
        } catch (error) {
            setError(true)
            toast.error(error.message)
        }

    }

    const handleResponse = (response, apiResponse) => {
        if (response.success) {
            setData(response.order[0])
            setIsLoading(false)
        } else if(apiResponse.status === 401) {
                navigate('/login')
                throw new Error(response.error)
        }
        else {
            setIsLoading(false)
            throw new Error(response.error);
        }
    }


    useEffect(() => {
        fetchUserDetails()
    }, [])

    if(isLoading) {
            return (
                <section className='wrapper'>
                    <div className="col-lg-12 h-100">
                    <div className="parent-loader h-100">
                        <Loader />
                    </div>
                    </div>
                </section>
            )
    } else {
        return (
            <div className="wrapper" style={{ overflowX: 'hidden' }}>
                <div className="row h-100 justify-content-center">
                    <div className="col-lg-12">
                        <h2 className='pb-4 m-0'><i onClick={() => window.history.back()} class="bi bi-arrow-left-circle-fill fs-3 me-3 my-auto pointer"></i>Order Reciept</h2>
                        <section className="h-100">
                            {/* <div className="d-flex align-items-center justify-content-end pt-4 gap-4">
                                <div>
                                    <button className='btn btn-danger btn-lg mt-3'>
                                        Download
                                    </button>
                                </div>
                                <div>
                                    <button className='btn btn-danger btn-lg mt-3'>
                                        Print
                                    </button>
                                </div>
                            </div> */}
                            <div className='shadow'>
                                <PDFViewer width='100%' style={{ minHeight: '100vh' }}>
                                    <Document>
                                        <Page size='A4' style={styles.page}>
                                            <View style={styles.section}>
                                                <Image style={styles.image} src="/assets/logo/e-tijwaal.png" />
                                                <Text style={styles.heading}>ORDER RECEIPT</Text>
                                            </View>
                                            <View style={styles.verticalSection}>
                                                <Text style={styles.company}>eTijwaal</Text>
                                                {/* <Text style={styles.address}>
                                                    6 Raffles Bouleard,
                                                </Text>
                                                <Text style={styles.address}>
                                                    Justco @ Marina Square,
                                                </Text>
                                                <Text style={styles.address}>
                                                    #03-308,
                                                </Text>
                                                <Text style={styles.address}>
                                                    Singapore, 039594
                                                </Text> */}
                                            </View>
    
                                            <View style={styles.verticalSection}>
                                                <Text style={styles.company}>{data?.first_name} {data?.last_name}</Text>
                                                <Link style={styles.company}>{data?.email}</Link>
                                            </View>
    
    
                                            {/* <Line x1={100} y1={100} x2={500} y2={500} style={{ strokeWidth: 2, color: 'black' }} strokeWidth={10} /> */}
    
                                            <View style={styles.lineBreak}></View>
                                            <View style={styles.ordersWrapper}>
                                                <View style={styles.page}>
                                                    <Text style={styles.order}>Order ID</Text>
                                                    <Text style={styles.address}>{data?.systemOrderId}</Text>
                                                </View>
                                                <View style={styles.page}>
                                                    <Text style={styles.order}>Total Paid</Text>
                                                    <Text style={styles.address}> US ${data?.price}</Text>
                                                </View>
                                                <View style={styles.page}>
                                                    <Text style={styles.order}>Date Paid</Text>
                                                    <Text style={styles.address}>{data?.paymentDate === null ? data?.createdAt?.slice(0,10) + ' ' + data?.createdAt?.slice(11,16)  :  data?.paymentDate?.slice(0,10) + ' ' + data?.paymentDate?.slice(11,16)}</Text>
                                                </View>
                                                <View style={styles.page}>
                                                    <Text style={styles.order}>Payment Method</Text>
                                                    <Text style={styles.address}> {data?.paymentDate === null ? '' : 'CARD'} {data?.paymentDate !== null && data?.promoCodeId !== null ? '-' : ''} {data?.promoCodeId === null ? '' : 'PROMO'}</Text>
                                                    {/* <Text style={styles.address}> </Text> */}
                                                </View>
    
                                            </View>
                                            <View style={styles.lineBreak}></View>
                                            <View style={{ marginBottom: 10 }}>
                                                <Text style={{ fontSize: 12, fontWeight: 'extrabold', marginHorizontal: 30, marginTop: 25, marginBottom: 15 }}>Summary</Text>
                                                <View style={{ flexDirection: 'column', marginHorizontal: 30 }}>
                                                    <View style={{ flexDirection: 'row', fontSize: 14 }}>
                                                        <View style={{ border: '1px solid black',borderBottom:0, padding: 5, alignItems: 'center', justifyContent: 'center', width: '10%' }}>
                                                            <Text style={{ fontWeight: 'extrabold', fontSize: 12 }}>Qty</Text>
                                                        </View>
                                                        <View style={{ width: '60%', border: '1px solid black', borderBottom:0, alignItems: 'center', justifyContent: 'center' }}>
                                                            <Text style={{ fontWeight: 'extrabold', textAlign: 'center', fontSize: 12 }}>Product</Text>
                                                        </View>
                                                        <View style={{ border: '1px solid black',borderBottom:0, alignItems: 'center', justifyContent: 'center', padding: 5, width: '15%' }}>
                                                            <Text style={{ fontSize: 12 }}>Unit Price</Text>
                                                        </View>
                                                        <View style={{ border: '1px solid black', borderBottom:0, alignItems: 'center', justifyContent: 'center', padding: 5, width: '15%' }}>
                                                            <Text style={{ fontSize: 12 }}>Amount</Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', fontSize: 14 }}>
                                                        <View style={{ border: '1px solid black', padding: 5, alignItems: 'center', justifyContent: 'center', width: '10%' }}>
                                                            <Text style={{ fontWeight: 'extrabold', fontSize: 12 }}>1</Text>
                                                        </View>
                                                        <View style={{ width: '60%', border: '1px solid black', alignItems: 'center', justifyContent: 'center', paddingHorizontal: 20 }}>
                                                            <Text style={{ fontWeight: 'extrabold', textAlign: 'center', fontSize: 12 }}>e-sim - {data?.packageName}</Text>
                                                        </View>
                                                        <View style={{ border: '1px solid black', alignItems: 'center', justifyContent: 'center', padding: 5, width: '15%' }}>
                                                            <Text style={{ fontSize: 12 }}> US ${data?.price}</Text>
                                                        </View>
                                                        <View style={{ border: '1px solid black', alignItems: 'center', justifyContent: 'center', padding: 5, width: '15%' }}>
                                                            <Text style={{ fontSize: 12 }}> US ${data?.price}</Text>
                                                        </View>
                                                    </View>
    
                                                </View>
                                                <Text style={{ fontSize: 12, fontWeight: 'extrabold', marginHorizontal: 30, marginTop: 25, marginBottom: 15, textAlign: 'right' }}>Total Price: US ${data?.price}</Text>
    
                                            </View>
                                            <View style={styles.lineBreak}></View>
    
                                            <View style={{ fontSize: 12, marginHorizontal: 30, marginTop: '25%' }}>
                                                {/* <Text style={styles.address}>Company Registration No: 201836421Z.</Text>
                                                <Text style={styles.address}>Registered Office: 6 Raffles Boulevard, Justco @ Marina Square, #03-308, Singapore, Singapore,
                                                    039594, Singapore.
                                                </Text> */}
                                                <Text style={styles.address}>Need help? Contact us at <Link>support@etijwaal.com</Link>.</Text>
                                            </View>
                                        </Page>
                                    </Document>
                                </PDFViewer>
    
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        )
    }
};

