import React from 'react'
import { useNavigate } from 'react-router-dom'

const LogoutModal = () => {
    const navigate = useNavigate()
    return (
        <>

            <div class="modal fade" id="LogoutModal" tabindex="-1" aria-labelledby="LogoutModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">

                        <div class="modal-body">
                            <p className="text-center my-5 clr-secondary heading">
                                Are You Sure?
                            </p>
                        </div>
                        <div class="modal-footer border-0 mx-auto  ">
                            <button type="button" class="btn dark-btn" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn primary-btn" data-bs-dismiss="modal" onClick={() => {
                                localStorage.removeItem("customerEtijwaal")
                                navigate("/login")
                            }
                            } >Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LogoutModal
