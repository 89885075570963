import { useEffect, useState } from "react";

export default function useMediaQuery() {
    const [width, setWidth] = useState(window.innerWidth)




    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth)
        })
    }, [width])

    return { width };
}