import React, { useContext, useEffect, useState } from "react";
import './css/styles.css'
import { BrowserRouter, Navigate, Route, Router, Routes, useNavigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import { useDispatch, useSelector } from 'react-redux';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Toaster } from 'react-hot-toast';
import Login from './components/auth/Login';
import OrderListing from './pages/order/[slug]';

import Routeprivate from './store/routeprivate'
import { CategoryScale } from "chart.js";
import { Chart } from "chart.js/auto";
import Order from "./pages/order/Order";
import NotFound from "./components/NotFound";
import AccountInfo from "./pages/AccountInfo";
import AirmoneyMembership from "./pages/AirmoneyMembership";
import SavedCards from "./pages/SavedCards";
import ReferEarn from "./pages/ReferEarn";
import PaymentOrder from "./pages/payment/[slug]";
import { SkeletonTheme } from "react-loading-skeleton";
import Pdf from "./pages/pdfViewer/Pdf";
import { PDFViewer } from "@react-pdf/renderer";
import MyEsims from "./pages/myEsims/MyEsims";
import Topups from "./pages/topups/Topups";
function App() {
  Chart.register(CategoryScale)
 

  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const getuser = localStorage.getItem("customerEtijwaal");
  const usertoken = JSON.parse(getuser);
  if (usertoken) {
    dispatch({ type: "LOGIN", payload: getuser.token });
  }


  return (
    <div className="App" style={{ minHeight: '100vh' }}>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            fontSize: "16px ",
            padding: "10px 20px",
          },
        }}
      />

        <BrowserRouter>

          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/' element={<Login />} />
            <Route path='*' element={<NotFound />} />


        

            <Route element={<Routeprivate />}>
              {/* <Route element={<AppLayout />}> */}

              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/orders" element={<Order />} />
              <Route path="/myEsims" element={<MyEsims />} />
              <Route path="/topups" element={<Topups />} />
              <Route path="/orders/:id" element={<OrderListing />} />
              <Route path="/accountinfo" element={<AccountInfo />} />
              <Route path="/airmoneymembership" element={<AirmoneyMembership />} />
              <Route path="/savedcards" element={<SavedCards />} />
              <Route path="/referearn" element={<ReferEarn />} />
              <Route path="/topupOrders/:id/systemOrderId" element={<PaymentOrder />} />
              <Route path="/pdf/id" element={<Pdf />} />

              {/* </Route> */}
            </Route>



          </Routes>



        </BrowserRouter>
    </div >


  );
}

export default App;
