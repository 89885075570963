// src/components/PrivateRoute.js

import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Sidebar from "../layout/Sidebar";
import Header from "../layout/Header";
import Footer from "../layout/Footer";


const Routeprivate = ({ path, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return isAuthenticated ? (
    <div className="container-fluid min-vh-100 ">
      <div className="row  ">
        <div className="col-lg-3 col-xl-2 px-0 sidebar-sticky">
          <Sidebar />
        </div>
        <div className="col-lg-9 col-xl-10 px-0 bg-light main-content">
          <Header />
          <Outlet />
          <Footer />
        </div>
      </div>
    </div>

  ) : (
    <Navigate to="/" />
  );
};

export default Routeprivate;
