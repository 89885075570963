import React from 'react'

const SavedCards = () => {
    return (
        <>
            <section className='wrapper'>
                Saved Cards
            </section>
        </>
    )
}

export default SavedCards
