import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Sidebar from './layout/Sidebar';
import reduxstore from './store/store';
import { createStore } from "redux";

const store = createStore(reduxstore);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>

    
      <App />


  </Provider >
);


