import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import AOS from "aos";
import "aos/dist/aos.css";
import axios from 'axios';
import { app_url } from '../../config';


const ForgetPassword = () => {
    const [loading, setloading] = useState(false)
    const [Email, setEmail] = useState('')
    const [EmailSec, setEmailSec] = useState(true)
    const [Otp, setOtp] = useState('')
    const [OtpSec, setOtpSec] = useState(false)
    const [password1, setpassword1] = useState('')
    const [password2, setpassword2] = useState('')
    const [passwordSec, setpasswordSec] = useState(false)
    const [heading, setheading] = useState('Enter Your Purchasing Email')

    const SubmitEmail = (e) => {
        setloading(true)
        e.preventDefault()
        if (Email === '') {
            toast.error('Enter Your Email')
            setloading(false)
        } else {
            axios.post(`${app_url}/api/customer/emailSendForOtp?email=${Email}`)
                .then(res => {
                    console.log(res, Otp)
                    if (res.data.success === true) {
                        setEmailSec(false)
                        setOtpSec(true)
                        console.log(Email)
                        toast.success(res.data.message)
                        setloading(false)
                        setheading(`We've sent you verification OTP on email`)
                    }
                })
                .catch(err => {
                    console.log(err)
                    toast.error(err.response.data.message)
                    setloading(false)
                    console.log(Otp)
                })
        }
    }

    const SubmitOtp = (e) => {
        setloading(true)
        e.preventDefault()
        if (Otp === '') {
            toast.error('Enter Your OTP')
            setloading(false)
        }
        else {
            axios.get(`${app_url}/api/customer/matchOtpByEmailAndOtp?emailId=${Email}&otp=${Otp}`)
                .then(res => {
                    console.log(res)
                    if (res.data.success === true) {
                        setOtpSec(false)
                        setpasswordSec(true)
                        console.log(Otp)
                        toast.success(res.data.message)
                        setloading(false)
                        setheading(`Enter Your New Password`)
                    }
                })
                .catch(err => {
                    console.log(err)
                    toast.error(err.response.data.message)
                    setloading(false)
                })
        }
    }
    const SubmitPassword = (e) => {
        setloading(true)
        e.preventDefault()
        if (password1 === '') {
            toast.error('Enter Your New Password')
            console.log(password1, password2)
            setloading(false)
        } else if (password2 === '') {
            toast.error('Confirm Your New Password')
            console.log(password1, password2)
            setloading(false)
        }
        else if (password1 != password2) {
            toast.error(`Password Dosen't Match`)
            setloading(false)
        }
        else {
            axios.post(`${app_url}/api/customer/resetPasswordOnLoginScreen?emailId=${Email}&password=${password1}`)
                .then(res => {
                    console.log(res)
                    if (res.data.success === true) {
                        setOtpSec(true)
                        console.log('changed', res)
                        toast.success(res.data.message)
                        setloading(false)
                        document.querySelector('.closeforget').click()
                    }
                })
                .catch(err => {
                    console.log(err)
                    toast.error(err.response.data.message)
                    setloading(false)
                })
        }
    }
    const clearForm = () => {
        setEmailSec(true)
        setOtpSec(false)
        setpasswordSec(false)
        setEmail('')
        setOtp('')
        setpassword1('')
        setpassword2('')
    }

    return (
        <>
            <div class="modal fade" id="ForgetPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="ForgetPasswordModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <form class="modal-content py-3  px-md-3" onSubmit={EmailSec ? SubmitEmail : OtpSec ? SubmitOtp : passwordSec ? SubmitPassword : ''}>
                        <div class="modal-header border-0 mx-auto">
                            <h1 class="modal-title clr-secondary fs-3" id="ForgetPasswordModalLabel">{heading}</h1>
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div class="modal-body py-3"  >
                            {EmailSec ?
                                <input type="email" className={`form-control py-2 my-3 `} value={Email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" name="" id="" />
                                : OtpSec ?
                                    <>
                                        <p className='text-black'>
                                            This email may take a few minutes to arrive. Check the inbox for no-reply@etijwaal.com.
                                            <br /><br />
                                            Note: Check your spam folder in case the email was incorrectly identified
                                            <br /><br />
                                            You will have 10 minutes to confirm an OTP, after that you will need to request a new verification OTP.
                                            <br /><br />
                                        </p>
                                        <input type="text" className={`form-control py-2 my-3 `} value={Otp} onChange={(e) => setOtp(e.target.value)} placeholder="OTP" name="" id="" />
                                    </>
                                    : passwordSec ?
                                        <>
                                            <input type="text" disabled={loading} className={`form-control py-2 my-3 `} value={password1} onChange={(e) => setpassword1(e.target.value)} placeholder="New Password" name="" id="" />
                                            <input type="text" disabled={loading} className={`form-control py-2 my-3 `} value={password2} onChange={(e) => setpassword2(e.target.value)} placeholder="Confirm Password" name="" id="" />
                                        </>
                                        : ''
                            }
                        </div>
                        <div class="modal-footer border-0 mx-auto">

                            <button type="button" class="btn dark-btn text-small closeforget" onClick={clearForm} data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn primary-btn text-small" disabled={loading}>
                                <span class=" text-small" role="status" >Next</span>
                                {loading ?
                                    <span class="spinner-border spinner-border-sm text-small ms-2" aria-hidden="true"></span> : ''}
                            </button>


                        </div>
                    </form>
                </div>
            </div >
        </>
    )
}

export default ForgetPassword
