import React from 'react'

const PkgDetailModal = ({ data }) => {
    return (
        <>


            <div class="modal fade" id="pkgdetailmodal" tabindex="-1" aria-labelledby="pkgdetailmodalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content rounded-5">
                        <div className="card order_history_card h-100 border-0 ">
                            <div className="card-body">
                                {data?.map((item, index) => (
                                    <ul>
                                        <li>
                                            <b>Package Name: </b>
                                            <span>{item.packageName}</span>
                                        </li>
                                        <li>
                                            <b>Sim Type:</b>
                                            <span>{item.simType}</span>
                                        </li>
                                        <li>
                                            <b>ICCID: </b>
                                            <span>{item.iccid}</span>
                                        </li>
                                        <li>
                                            <b>IMEI:</b>
                                            <span>{item.imei === null ? '--' : item.imei}</span>
                                        </li>
                                        <li>
                                            <b>MSISDN:</b>
                                            <span>{item.msisdn === null ? '--' : item.msisdn}</span>
                                        </li>
                                        <li>
                                            <b>IMSI:</b>
                                            <span>{item.imsi === null ? '--' : item.imsi}</span>
                                        </li>
                                        <li>
                                            <b>Apn Type:</b>
                                            <span>{item.apnType === null ? '--' : item.apnType}</span>
                                        </li>
                                        <li>
                                            <b>Apn Value:</b>
                                            <span>{item.apnValue === null ? '--' : item.apnValue}</span>
                                        </li>
                                        <li>
                                            <b>Status:</b>
                                            <span>{item.status === null ? 'Not_Active' : item.status}</span>
                                        </li>
                                        <li>
                                            <b>Validity:</b>
                                            <span>{item.allowedDays}{item.allowedDays === 1 ? ' Day' : ' Days'} </span>
                                        </li>
                                        <li>
                                            <b>Remaining Data:</b>
                                            <span>{item.remainingUsage === null ? '--' : item.remainingUsage + 'MB'}</span>
                                        </li>
                                        <li>
                                            <b>Expiration Date:</b>
                                            <span>{item.expiresAt === null ? '--' : item.expiresAt.slice(0, 10)}</span>
                                        </li>
                                        <li>
                                            <b>Last Upadate:</b>
                                            <span>{item.updatedAt === null ? '--' : item.updatedAt.slice(0, 10)}</span>
                                        </li>
                                        <li>
                                            <b>Purchasing Date:</b>
                                            <span>{item.createdAt === null ? '--' : item.createdAt.slice(0, 10)}</span>
                                        </li>
                                    </ul>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PkgDetailModal
