import React from 'react'

const ReferEarn = () => {
    return (
        <>
            <section className='wrapper'>
                rafer & earn
            </section>
        </>
    )
}

export default ReferEarn
