import React from 'react'

const PasswordChange = () => {
    return (
        <>
            <div class="modal fade" id="PasswordChangeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="PasswordChangeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header border-0 mx-auto">
                            <h1 class="modal-title clr-secondary fs-3" id="PasswordChangeModalLabel">Change Password</h1>
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div class="modal-body py-4">
                            <form action="">
                                <input type="text" className='form-control py-1 my-3 ' placeholder="Current Password" name="" id="" />
                                <input type="text" className='form-control py-1 my-3 ' placeholder="New Password" name="" id="" />
                                <input type="text" className='form-control py-1 my-3 ' placeholder="Re-Type Password" name="" id="" />
                            </form>
                        </div>
                        <div class="modal-footer border-0 mx-auto">
                            <button type="button" class="btn dark-btn text-small " data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn primary-btn text-small">Save Changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordChange
