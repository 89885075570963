import React, { useState } from "react";
import { Bar } from "react-chartjs-2";



const Leadsales = () => {
    const [selected, setSelected] = useState("All Time");
    const acquisitionData = selected === 'All Time' ? [400, 300, 600, 140, 133, 117, 250] : selected === 'This Year' ? [400, 600, 355, 475, 260, 566, 300] : selected === 'This Week' ? [400, 300, 540, 240, 300, 143, 400] : ''

    const data = {
        labels: ["", "", "", "", "", "", ""],
        datasets: [
            {
                label: 'All Time Sale',
                data: acquisitionData,
                backgroundColor: ["gray", "#ED8936", "gray", "#ED8936", "gray", "#ED8936", "gray"],
                borderColor: "transparent",
                borderRadius: 5,

            },
        ],
    };

    return (

        <>
            <div className="mb-4">
                <button onClick={() => setSelected("All Time")} className={selected === "All Time" ? "btn dark-btn" : "btn chart-btn"} bg="transparent">All time</button>
                <button onClick={() => setSelected("This Year")} className={selected === "This Year" ? "btn dark-btn" : "btn chart-btn"} bg="transparent">All time</button>
                <button onClick={() => setSelected("This Week")} className={selected === "This Week" ? "btn dark-btn" : "btn chart-btn"} bg="transparent">All time</button>
                   </div>
            <Bar data={data}
                options={{
                    plugins: {
                        legend: {
                            display: false,
                        }
                    },
                    responsive: true,
                    scales: {
                        x: {

                            grid: {
                                display: false
                            }
                        },
                        y: {
                            beginAtZero: false,
                            min: 100,
                            max: 600,
                            grid: {
                                drawTicks: false,

                            },
                            ticks: {
                                stepSize: 100,
                                callback: (value) => `$${value}`,
                            }
                        }
                    },
                    aspectRatio: 4.5
                }}
            />
        </>
    );
};
export default Leadsales
