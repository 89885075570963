import React from 'react'

const AirmoneyMembership = () => {
    return (
        <>
            <section className='wrapper'>
                AirmoneyMembership

            </section>
        </>
    )
}

export default AirmoneyMembership
