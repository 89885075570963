import { Link, NavLink } from "react-router-dom";
import Avatar from "../components/Avatar";
import useWindowWidth from "../custom hooks/useWindowWidth";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useEffect } from "react";
// import { setOpen } from "../redux/slices/offCanvasSlice";



export default function Sidebar() {

    const location = useLocation()
    // get user detail from localstorage 
    const getdataLocalStorage = localStorage.getItem('customerEtijwaal')
    const userdetail = JSON.parse(getdataLocalStorage)

  
    // close sidebar on location change 
    useEffect(() => {
        document.querySelector('.sidebar-close').click()
    }, [location])




    return (


        <div class="offcanva-custome offcanvas offcanvas-start h-100" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header pb-0 d-lg-none">
                <button type="button" class="btn-close  ms-auto sidebar-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body sidebar px-0">
                <div className="side_bar_top px-4">
                    <div className="p-4 pt-lg-4 pt-0 text-center ">

                        <img src="/assets/logo/e-tijwaal.png" className="logo" />

                    </div>
                    <div className="user d-flex align-items-center py-2 px-3 bg-light gap-3">
                        <Avatar />
                        <div className="user_details rounded">
                            <h6 className="mt-0 mb-0 text-capitalize ">{userdetail?.customer.first_name} {userdetail?.customer.last_name}</h6>
                            <span className="small text-capitalize ">{userdetail.customer.userType}</span>
                        </div>
                    </div>
                </div>
                <nav>
                    <ul className="mt-3">
                        {/* <li>
                            <NavLink to="/dashboard" className="active_link">
                                <button>Dashboard</button>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/accountinfo" className="active_link">
                                <button> Account Information</button>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/airmoneymembership" className="active_link">
                                <button> Airmoney & Membership</button>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/savedcards" className="active_link">
                                <button> Saved Cards</button>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/referearn" className="active_link">
                                <button> Refer & Earn</button>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/orders" className="active_link">
                                <button> Orders</button>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/myEsims" className="active_link">
                                <button> My eSims</button>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/topups" className="active_link">
                                <button>Top-up</button>
                            </NavLink>
                        </li>


                    </ul>
                </nav>
            </div>
        </div>

    )
};