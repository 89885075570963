import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { app_url } from '../../config'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Loader from '../../components/Loader';
import toast from 'react-hot-toast';
import PkgDetailModal from '../../components/modals/PkgDetailModal';

const OrderListing = () => {
  const userdata = localStorage.getItem('customerEtijwaal')
  const token = JSON.parse(userdata).token
  const [data, setdata] = useState([])
  const [loading, setloading] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    setloading(true)
    axios.get(`${app_url}/api/order/getOrderAndLineBySOICportal?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(res => {
        setloading(false)
        if (res.status === 401) {
          navigate('/')
        }
        setdata(res.data)
        console.log(res);
      })
      .catch(err => {
        setloading(false)
        console.log(err)
        toast.error(err.response.data.message)

      })
  }, [])

  const settings = {
    dots: false,
    // infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200, // Change settings for screens less than or equal to 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Change settings for screens less than or equal to 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576, // Change settings for screens less than or equal to 576px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className='wrapper overflow-hidden '>
        <p className="heading">
          <i class="bi bi-arrow-left-circle-fill fs-3 me-3 my-auto pointer" onClick={() => window.history.back()} ></i>
          Order Details</p>



        {loading ? <div className='parent-loader'><Loader /></div> :
          <>
            {data?.order?.map((item, index) => (
              <>
                <div className='row mb-4 justify-content-between '>
                  <div className="col-md ">
                    <div className='h-100'>
                      <p className="heading-m my-2">Order Information</p>
                      <div className="card min-vp-80 card-2">
                        <div className="card-body pt-1 px-0">
                          <div className="user_info px-md-4 px-2">
                            <label htmlFor="Email">Order Id</label>
                            <p>{item.systemOrderId}</p>
                          </div>
                          <div className="user_info px-md-4 px-2">
                            <label htmlFor="Email">Order Date</label>
                            <p>{new Date(item.createdAt).toLocaleString()}</p>
                          </div>
                          <div className="user_info px-md-4 px-2">
                            <label htmlFor="Email">Order Status</label>
                            {/* <p>{item.promoCodeId === null ? "Not Used" : 'Used'}</p> */}
                            <p className='text-capitalize'>{item.status}</p>
                          </div>
                          <div className='px-2 px-md-4'>
                            <button className='btn btn-danger mt-3'>
                               <Link to={`/pdf/id?id=${item.id}`} className='text-white'>View Reciept</Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md ">

                    <p className="heading-m my-2">Payment Details</p>
                    <div className="card min-vp-80 card-2">
                      <div className="card-body pt-1 px-0">

                        <div className="user_info px-md-4 px-2 ">
                          <label htmlFor="Email">PAYMENT METHOD</label>
                          <p> {item?.paymentDate === null ? '' : 'CARD'} {item?.paymentDate !== null && item?.promoCodeId !== null ? '-' : ''} {item?.promoCodeId === null ? '' : 'PROMO'}</p>

                        </div>

                        <div className="user_info px-md-4 px-2  ">
                          <label htmlFor="Email">TOTAL PRICE</label>
                          <p>{item.currency === 'usd' ? '$' : ''} {item.price} </p>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
                <div className='row mb-4 justify-content-between mt-10'>
                  <div className="col-md-6 mt-2">
                    <div className="card pkg-card card-2 h-100">

                      <div className="card-body">
                        <p className="heading coverage_info pt-0">
                          {item.packageName}
                        </p>
                        <img src={app_url + item.cardImage} className='coverage_info_img' alt="" />
                        <div className="coverage_info">
                          <label htmlFor="Email">Coverage</label>
                          <p>{item.shortInfo}</p>
                        </div>
                        <div className="coverage_info">
                          <label htmlFor="Email">Data</label>
                          <p>{item.includeData}</p>
                        </div>
                        <div className="coverage_info">
                          <label htmlFor="Email">Validity</label>
                          <p>{item.day === 1 ? `${item.day} day` : `${item.day} days`}</p>
                        </div>
                        <div className="coverage_info">
                          <label htmlFor="Email">Price</label>
                          <p>{item.currency === 'usd' ? "$" : ''} {item.packagePrice}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-md-2  pkgslider">
                    {loading ? <div className='parent-loader'><Loader /></div> : <>
                      {data?.lines?.map((item, index) => (
                        <>
                          {data?.lines?.length === 1 || data?.lines?.length === 2 ?
                            <div className="col-md-6">
                              <div className="card order_history_card h-100">

                                <img src={'/assets/images/esim.png'} className='coverage_info_img-2' alt="" />
                                <div className="card-body ">
                                  <p className="heading text-dark coverage_info_esim pt-0">
                                    Sim Detail
                                  </p>
                                  <ul>
                                    <li>
                                      <b className='text-dark'>Package Name: </b>
                                      <span className='text-dark'>{item.packageName}</span>
                                    </li>
                                    <li>
                                      <b className='text-dark'>Sim Type:</b>
                                      <span className='text-dark'>{item.simType}</span>
                                    </li>
                                    <li>
                                      <b className='text-dark'>ICCID: </b>
                                      <span className='text-dark'>{item.iccid}</span>
                                    </li>
                                    {/* <li>
                                      <b>IMEI:</b>
                                      <span>{item.imei === null ? '--' : item.imei}</span>
                                    </li>
                                    <li>
                                      <b>MSISDN:</b>
                                      <span>{item.msisdn === null ? '--' : item.msisdn}</span>
                                    </li>
                                    <li>
                                      <b>IMSI:</b>
                                      <span>{item.imsi === null ? '--' : item.imsi}</span>
                                    </li> */}
                                    {/* <li>
                                      <b>Apn Type:</b>
                                      <span>{item.apnType === null ? '--' : item.apnType}</span>
                                    </li>
                                    <li>
                                      <b>Apn Value:</b>
                                      <span>{item.apnValue === null ? '--' : item.apnValue}</span>
                                    </li> */}
                                    <li>
                                      <b className='text-dark'>Status:</b>
                                      <span className={`${item.status === "EXPIRE" ? 'text-danger fw-bold' : 'text-dark'}`}>{item.status === null ? 'Not_Active' : item.status}</span>
                                    </li>
                                    <li>
                                      <b className='text-dark'>Validity:</b>
                                      <span className='text-dark'>{item.allowedDays}{item.allowedDays === 1 ? ' Day' : ' Days'} </span>
                                    </li>
                                    <li>
                                      <b className='text-dark'>Remaining Data:</b>
                                      <span className='text-dark'>{item.remainingUsage === null ? '--' : item.remainingUsage + 'MB'}</span>
                                    </li>
                                    <li>
                                      <b className='text-danger'>Expiration Date:</b>
                                      <span className='text-danger fw-bold'>{item.expiresAt === null ? '--' : new Date(item.expiresAt).toLocaleString()}</span>
                                    </li>
                                    {/* <li>
                                      <b>Last Upadate:</b>
                                      <span>{item.updatedAt === null ? '--' :  new Date(item.updatedAt).toLocaleString() }</span>
                                    </li> */}
                                    <li>
                                      <b className='text-dark'>Purchasing Date:</b>
                                      <span className='text-dark'>{item.createdAt === null ? '--' :  new Date(item.createdAt).toLocaleString()}</span>
                                    </li>
                                  </ul>

                                </div>
                                {/* <div className="card-footer border-0 ">
                                  <button className='btn w-100 mb-3 btn btn-outline-light' data-bs-toggle="modal" data-bs-target="#pkgdetailmodal">
                                    Show More
                                  </button>
                                </div> */}
                              </div>
                            </div>
                            :
                            <Slider {...settings}>
                              <div className="px-2">
                                <div className=" w-100 card order_history_card h-100">
                                  <div className="card-body">
                                    <ul>

                                      <li>
                                        <b>Package Name: </b>
                                        <span>{item.packageName}</span>
                                      </li>
                                      {/* <li>
                                      <b>Sim Type:</b>
                                      <span>{item.simType}</span>
                                    </li> */}
                                      <li>
                                        <b>ICCID: </b>
                                        <span>{item.iccid}</span>
                                      </li>
                                      <li>
                                        <b>IMEI:</b>
                                        <span>{item.imei === null ? '--' : item.imei}</span>
                                      </li>
                                      <li>
                                        <b>MSISDN:</b>
                                        <span>{item.msisdn === null ? '--' : item.msisdn}</span>
                                      </li>
                                      <li>
                                        <b>IMSI:</b>
                                        <span>{item.imsi === null ? '--' : item.imsi}</span>
                                      </li>
                                      {/* <li>
                                      <b>Apn Type:</b>
                                      <span>{item.apnType === null ? '--' : item.apnType}</span>
                                    </li>
                                    <li>
                                      <b>Apn Value:</b>
                                      <span>{item.apnValue === null ? '--' : item.apnValue}</span>
                                    </li> */}
                                      {/* <li>
                                      <b>Status:</b>
                                      <span>{item.status === null ? 'Not_Active' : item.status}</span>
                                    </li> */}
                                      <li>
                                        <b>Validity:</b>
                                        <span>{item.allowedDays}{item.allowedDays === 1 ? ' Day' : ' Days'} </span>
                                      </li>
                                      <li>
                                        <b>Remaining Data:</b>
                                        <span>{item.remainingUsage === null ? '--' : item.remainingUsage + 'MB'}</span>
                                      </li>
                                      {/* <li>
                                      <b>Expiration Date:</b>
                                      <span>{item.expiresAt === null ? '--' : item.expiresAt.slice(0, 10)}</span>
                                    </li> */}
                                      {/* <li>
                                      <b>Last Upadate:</b>
                                      <span>{item.updatedAt === null ? '--' : item.updatedAt.slice(0, 10)}</span>
                                    </li>
                                    <li>
                                      <b>Purchasing Date:</b>
                                      <span>{item.createdAt === null ? '--' : item.createdAt.slice(0, 10)}</span>
                                    </li> */}
                                    </ul>

                                  </div>
                                  <div className="card-footer border-0 ">
                                    <button className='btn w-100 mb-3 btn btn-outline-light' data-bs-toggle="modal" data-bs-target="#pkgdetailmodal">
                                      Show More
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Slider>




                          }
                        </>
                      ))}
                    </>
                    }
                  </div>

                </div>
              </>
            ))}
          </>
        }



      </section>

      <PkgDetailModal data={data?.lines} />
    </>
  )
}

export default OrderListing
