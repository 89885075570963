import React, { useEffect } from 'react'
import Loader from '../Loader'
import { Link, useNavigate } from 'react-router-dom'

const Orders_Table = ({ tabledata, indexOfFirstItem, loading }) => {
    const Navigate = useNavigate()
    console.log(tabledata)



    return (
        <>
            <div className="table-responsive">
                <table class="table table-striped rounded custome-table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Order Id</th>
                            <th scope="col">package Name</th>
                            <th scope="col">Promo Code</th>
                            <th scope="col">Price</th>
                            <th scope="col">status</th>
                            <th scope="col">Payment Date</th>
                            <th scope="col">created at</th>
                            <th scope="col">updated at</th>
                            <th scope="col narrow-col">
                                Actions
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            loading ?
                                <>
                                    <td colSpan={14} className="table-parant-loader">
                                        <Loader />
                                    </td>
                                </>
                                :
                                tabledata?.data?.result?.length === 0 ? <><td colSpan={10}><p className="text-center heading-m mt-3"> No Result Found!</p></td> </> :
                                    tabledata?.data?.result?.map((item, i) => (
                                        <>
                                            <tr title='Double Click To Show Details' onDoubleClick={() => { Navigate(`/orders/${item.systemOrderId}`) }} key={i}>
                                                <td scope="row">{indexOfFirstItem + i + 1}</td>
                                                <td>{item.systemOrderId}</td>
                                                <td >{item.packageName}</td>
                                                <td >{item.promoCodeId === null ? "Not Used" : 'Used'}</td>
                                                <td >{item.currency === 'usd' ? '$' : ''} {item.price}</td>
                                                <td >{item.status}</td>
                                                <td >{item.paymentDate === null ? '--' : item.paymentDate}</td>
                                                <td >{item.createdAt}</td>
                                                <td >{item.updatedAt}</td>
                                                <td>
                                                    <li class="nav-item dropdown table-action-dd">
                                                        <Link class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="bi bi-three-dots-vertical fs-4 fw-bold"></i>
                                                        </Link>
                                                        <ul class="dropdown-menu">
                                                            <li><Link class="dropdown-item" to={`/orders/${item.systemOrderId}`}>View</Link></li>
                                                            <li><Link class="dropdown-item" to="#">Delete</Link></li>
                                                        </ul>
                                                    </li>
                                                </td>
                                            </tr>
                                        </>
                                    ))
                        }
                    </tbody>

                </table>
            </div>
        </>
    )
}

export default Orders_Table
