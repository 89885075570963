import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Pagination = ({
    currentPage,
    totalPages,
    onPageChange,

}) => {
    const pageNumbers = Array.from(
        { length: totalPages },
        (_, index) => index + 1
    );
    const activeIndex = pageNumbers.indexOf(currentPage);

    const startIndex = Math.max(0, activeIndex - 2);
    const endIndex = Math.min(pageNumbers.length - 1, startIndex + 3);
    const buttonsToDisplay = pageNumbers.slice(startIndex, endIndex + 1);

    return (
        <div >
            <ul className="pagination p-0 m-0 justify-content-center">
                <li class="page-item">
                    <button
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <i class="bi bi-caret-left-fill"></i>
                    </button>
                </li>
                {buttonsToDisplay.map((pageNumber) => (
                    <NavLink
                        key={pageNumber}
                        onClick={() => onPageChange(pageNumber)}
                        href="#"
                        className={pageNumber === currentPage ? "page-active" : ""}
                    >
                        <li>
                            <button>{pageNumber}</button>
                        </li>
                    </NavLink>
                ))}
                <li class="page-item">
                    <button
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <i class="bi bi-caret-right-fill"></i>
                    </button>
                    {/* <li>  <button onClick={() => onPageChange(currentPage + 1, indexOfLastItem)} > <img src={next} alt="icon" /></button> */}
                </li>
            </ul>
        </div>
    );
};

export default Pagination;
