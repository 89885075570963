import { Link, useNavigate } from "react-router-dom";
import Avatar from "../components/Avatar";
import useWindowWidth from "../custom hooks/useWindowWidth";
import { GiHamburgerMenu } from 'react-icons/gi'
import Sidebar from "./Sidebar";
import Footer from "./Footer";
// import { setOpen } from "../redux/slices/offCanvasSlice";
import { useDispatch } from "react-redux";
import LogoutModal from "../components/auth/LogoutModal";

export default function Header() {
  const navigate = useNavigate()
  const { width } = useWindowWidth();
  const dispatch = useDispatch()


  // const openSideMenu = () => {
  //   dispatch(setOpen())
  // }


  return (
    <>


      <nav >
        {/* <div class="container-fluid"> */}
        <div className="col-12">
          <div className="header">
            <a href="https://wa.me/97366555247" className="d-lg-block d-none">Help: +973 66555 247</a>
            <i class="bi bi-list d-lg-none text-white fs-2" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample"></i>
            <div className="d-flex  gap-3">
              <button type="button" data-bs-toggle="modal" data-bs-target="#LogoutModal" className="btn btn-transparent btn-sm text-white ">Logout
              </button>
              <Avatar />
            </div>
          </div>
        </div>
        <LogoutModal />
        {/* </div> */}
      </nav>




    </>
  )
};
