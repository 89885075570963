import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

  export default function OrderCompletedModal()
 {
    const navigate = useNavigate();


    const moveToOrders = () => navigate('/orders');

        // useEffect(() => {
        //         document.querySelector('.OrderCompletedModal').click()
        // }, [])

        return(
        <>
        <button type="button" class="btn btn-primary OrderCompletedModal d-none" data-bs-toggle="modal" data-bs-target="#OrderCompletedModal">
    
        </button>

    <div class="modal fade" id="OrderCompletedModal" data-bs-backdrop="static"  tabindex="-1" aria-labelledby="OrderCompletedModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div className='text-center mt-5'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                        </svg>
                    </div>
                    <p className="heading-m pt-5 text-capitalize text-center">Order Purchased Successfully...</p>
                    <p className="text-center pt-3 text-capitalize ">
                        Your Esim Details Has Been Send to you by Email
                    </p>
                </div>

                <div class={`modal-footer position-relative OrderCompletedModalbtn`}>
                    <button 
                    onClick={moveToOrders} 
                    type="button"
                    class="btn primary-btn mx-auto btn-sm"
                    data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
        </>
        )
}